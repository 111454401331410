export const seo = {
  url: 'achievements',
  title: {
    en: 'Achievements',
    es: 'Logros',
    ro: 'Realizări',
  },
  desc: {
    en: 'Discover numerous awards for the quality of services and achievements in business that we have earned over the past years. OMIDA Group is among the leading TSL companies in Poland.',
    es: 'Descubre numerosos premios por la calidad de servicios y logros en el negocio que hemos obtenido en los últimos años. OMIDA Group se encuentra entre las principales empresas TSL en Polonia.',
    ro: 'Descoperă numeroase premii pentru calitatea serviciilor și realizările în afaceri pe care le-am obținut de-a lungul ultimilor ani. Grupul Omida se află printre companiile de top TSL din Polonia.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    en: 'Achievements',
    es: 'Logros',
    ro: 'Realizări',
  },
  desc: {
    en: 'Discover numerous awards for the quality of services and achievements in business that we have earned over the past years. OMIDA Group is among the leading TSL companies in Poland.',
    es: 'Descubre numerosos premios por la calidad de servicios y logros en el negocio que hemos obtenido en los últimos años. OMIDA Group se encuentra entre las principales empresas TSL en Polonia.',
    ro: 'Descoperă numeroase premii pentru calitatea serviciilor și realizările în afaceri pe care le-am obținut de-a lungul ultimilor ani. Grupul Omida se află printre companiile de top TSL din Polonia.',
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citiți mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'Discover OMIDA Group awards',
        es: 'Descubre los premios del Grupo OMIDA',
        ro: 'Descoperă premiile OMIDA Group',
      },
      texts: [
        {
          en: `<span>OMIDA Group has received numerous prestigious awards for its excellent quality of logistics services, innovative approach to the TSL industry, and commitment to socio-economic activities. We have been honored for excellence in sustainable transportation, customer service, as well as active participation in social initiatives supporting education, environmental protection, and the development of local communities. The continuous pursuit of improving our services and positive impact on the environment has contributed to earning many accolades and recognition in the TSL industry.</span>`,
          es: `<span>OMIDA Group ha recibido numerosos premios prestigiosos por su excelente calidad de servicios logísticos, enfoque innovador en la industria TSL y compromiso con actividades socioeconómicas. Hemos sido honrados por la excelencia en transporte sostenible, servicio al cliente, así como por la participación activa en iniciativas sociales que apoyan la educación, la protección del medio ambiente y el desarrollo de comunidades locales. La búsqueda continua de mejorar nuestros servicios y el impacto positivo en el entorno ha contribuido a obtener muchos reconocimientos y prestigio en la industria TSL.</span>`,
          ro: `<span>Grupul OMIDA a primit numeroase premii prestigioase pentru excelenta calitate a serviciilor sale logistice, abordarea inovatoare în industria TSL și angajamentul în activități socio-economice. Am fost onorați pentru excelența în transportul durabil, servicii pentru clienți, precum și pentru participarea activă în inițiative sociale care susțin educația, protecția mediului și dezvoltarea comunităților locale. Căutarea continuă a îmbunătățirii serviciilor noastre și impactul pozitiv asupra mediului au contribuit la obținerea multor premii și recunoaștere în industria TSL.</span>`,
        },
      ],
    },
  ],
}

export const features = [
  {
    id: 'objective',
    icon: 'CSR_objective',
    subtitle: {
      en: 'Social',
      es: 'Sociales',
      ro: 'Social',
    },
    title: {
      en: 'Goals',
      es: 'Objetivos',
      ro: 'Goluri',
    },
    desc: {
      en: 'Realization of social goals impossible to achieve without business support',
      es: 'Realización de objetivos sociales imposibles de lograr sin el apoyo empresarial',
      ro: 'Realizarea unor obiective sociale imposibil de realizat fără sprijinul afacerilor',
    },
  },
  {
    id: 'social',
    icon: 'CSR_social',
    subtitle: {
      en: 'Social',
      es: 'Social',
      ro: 'Social',
    },
    title: {
      en: 'Support',
      es: 'Soporte',
      ro: 'A susține',
    },
    desc: {
      en: 'High quality of social facilities',
      es: 'Alta calidad de las instalaciones sociales',
      ro: 'Facilități sociale de înaltă calitate',
    },
  },
  {
    id: 'support',
    icon: 'CSR_support',
    subtitle: {
      en: 'Support for',
      es: 'Apoyo a la',
      ro: 'Suport pentru',
    },
    title: {
      en: 'Culture',
      es: 'Cultura',
      ro: 'Cultură',
    },
    desc: {
      en: 'Cultural support for local communities',
      es: 'Apoyo cultural a las comunidades locales',
      ro: 'Sprijin cultural pentru comunitățile locale',
    },
  },
  {
    id: 'charitywork',
    icon: 'CSR_charitywork',
    subtitle: {
      en: 'Work for',
      es: 'Trabajar por',
      ro: 'Lucrează pentru',
    },
    title: {
      en: 'Charity',
      es: 'Caridad',
      ro: 'Caritate',
    },
    desc: {
      en: 'Helping those in need disinterestedly',
      es: 'Ayudar a los necesitados desinteresadamente',
      ro: '',
    },
  },
  {
    id: 'development',
    icon: 'CSR_development',
    subtitle: {
      en: 'Professional Development and',
      es: 'Desarrollo Profesional y',
      ro: '',
    },
    title: {
      en: 'Training',
      es: 'Formación',
      ro: '',
    },
    desc: {
      en: 'Constant professional development thanks to the availability of training',
      es: 'Desarrollo profesional constante gracias a la disponibilidad de formación',
      ro: 'Dezvoltare profesională constantă datorită disponibilității pregătirii',
    },
  },
  {
    id: 'education',
    icon: 'CSR_education',
    subtitle: {
      en: 'Youth Development and',
      es: 'Desarrollo y Juvenil',
      ro: 'Dezvoltarea Tineretului și',
    },
    title: {
      en: 'Education',
      es: 'Educación',
      ro: 'Educaţie',
    },
    desc: {
      en: 'Involvement in education and youth development',
      es: 'Participación en la educación y el desarrollo de la juventud',
      ro: 'Implicarea în educație și dezvoltarea tinerilor',
    },
  },
  {
    id: 'ecology',
    icon: 'CSR_ecology',
    subtitle: {
      en: 'Care for the',
      es: 'Cuidado del medio',
      ro: 'Aveți grijă de',
    },
    title: {
      en: 'Environment',
      es: 'Ambiente',
      ro: 'Mediu inconjurator',
    },
    desc: {
      en: 'Limiting the negative impact of enterprises on the environment',
      es: 'Limitar el impacto negativo de las empresas en el medio ambiente',
      ro: 'Limitarea impactului negativ al întreprinderilor asupra mediului',
    },
  },
  {
    id: 'waste',
    icon: 'CSR_waste',
    subtitle: {
      en: 'Managing the',
      es: 'Gestión de',
      ro: 'Gestionarea',
    },
    title: {
      en: 'Waste',
      es: 'Residuos',
      ro: 'Deşeuri',
    },
    desc: {
      en: 'Rational management of natural resources and waste',
      es: 'Gestión racional de los recursos naturales y los residuos',
      ro: 'Gestionarea rațională a resurselor naturale și a deșeurilor',
    },
  },
  {
    id: 'ecology2',
    icon: 'CSR_ecology2',
    subtitle: {
      en: 'Activity',
      es: 'Actividad',
      ro: 'Activitate',
    },
    title: {
      en: 'Pro-ecological',
      es: 'Pro-ecológica',
      ro: 'Pro-ecologic',
    },
    desc: {
      en: 'Involving business partners in the chain of environmental responsibility and initiating joint pro-ecological activities',
      es: 'Involucrar a los socios comerciales en la cadena de responsabilidad ambiental e iniciar actividades pro-ecológicas conjuntas',
      ro: 'Implicarea partenerilor de afaceri în lanțul de responsabilitate ecologică și inițierea de activități comune pro-ecologice',
    },
  },
]
